<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.cooperationPartner.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.cooperationPartner.list.title"></app-i18n>
      </h1>

      <app-cooperation-partner-list-toolbar></app-cooperation-partner-list-toolbar>
      <app-cooperation-partner-list-filter></app-cooperation-partner-list-filter>
      <app-cooperation-partner-list-table></app-cooperation-partner-list-table>
    </div>
  </div>
</template>

<script>
import CooperationPartnerListFilter from '@/modules/cooperation-partner/components/cooperation-partner-list-filter.vue';
import CooperationPartnerListTable from '@/modules/cooperation-partner/components/cooperation-partner-list-table.vue';
import CooperationPartnerListToolbar from '@/modules/cooperation-partner/components/cooperation-partner-list-toolbar.vue';

export default {
  name: 'app-cooperation-partner-list-page',

  components: {
    [CooperationPartnerListFilter.name]: CooperationPartnerListFilter,
    [CooperationPartnerListTable.name]: CooperationPartnerListTable,
    [CooperationPartnerListToolbar.name]: CooperationPartnerListToolbar,
  },
};
</script>

<style>
</style>
